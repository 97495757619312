import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/gateway';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: '#F4FAFF'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 714000;

export default function ComingSoon() {
  return (
    <RootStyle>
      <Typography>Coming Soon...</Typography>
      <Typography>
        We're building more than just a hosting platform. We're creating a community of Strapi developers. Sign up to stay updated on our progress and be part of something special.

      </Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }} />
    </RootStyle>
  );
}
