import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired
};

export default function ProductList({ products,
  limit = Number.MAX_SAFE_INTEGER,
  ...other }) {
  return (
    <Grid container spacing={3} {...other}>
      {products.map((product, index) => {
        if (limit < index) {
          return null;
        }
        return (
          <Grid key={product.id} item xs={12} sm={6} md={3}>
            <ShopProductCard product={product} />
          </Grid>
        );
      })}
    </Grid>
  );
}
