// routes
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import PromiseMiddleware from 'redux-promise';
import { createLogger } from 'redux-logger';
import Router from './routes';
import * as Reducers from './redux';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

const history = createBrowserHistory();
// ----------------------------------------------------------------------
const readyStatePromise = () => (next) => (action) => {
  if (
    action.payload &&
    action.payload.toString &&
    action.payload.toString() === '[object Promise]'
  ) {
    next({
      type: `${action.type}_PENDING`,
      payload: action.payload
    });
  }
  return next(action);
};
const createStoreWithMiddleware = applyMiddleware(
  readyStatePromise,
  PromiseMiddleware,
  createLogger({ collapsed: true, level: 'error' })
)(createStore);
const stateReducer = combineReducers(Reducers);
const store = createStoreWithMiddleware(stateReducer);
export default function App() {
  return (
    <Provider store={store}>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Router history={history} />
      </ThemeConfig>
    </Provider>
  );
}
