import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
//
import { useEffect, useState } from 'react';
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': {
    height: CHART_HEIGHT
  },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

const CHART_DATA = [
  { name: 'Social Network Apps', data: [80, 50, 30, 40, 100, 20] },
  { name: 'eCommerce Apps', data: [20, 30, 40, 80, 20, 80] },
  { name: 'Others', data: [44, 76, 78, 13, 43, 10] }
];

export default function AppCurrentSubject() {
  const theme = useTheme();
  const types = [
    'histogram',
    'line',
    'radar',
    'heatmap',
    'area',
    'bar',
    // 'candlestick',
    // 'treemap',
    // 'bubble',
    // 'scatter',
    // 'pie',
    // 'donut',
    // 'radialBar',
    // 'boxPlot',
    // 'polarArea',
    //  'rangeBar'
  ];
  const [time, setTime] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setTime(types ? (time + 1) : 0);
    }, 5000);
  }, [time]);
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: 2 },
    fill: { opacity: 0.48 },
    legend: { floating: true, horizontalAlign: 'center' },
    xaxis: {
      categories: ['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math'],
      labels: {
        style: {
          colors: [
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary,
            theme.palette.text.secondary
          ]
        }
      }
    }
  });
  const selectType = (index) => types[(time + index) % types.length];
  return (
    <Card>
      <CardHeader title="CMS Usage on platforms" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart
          key={time}
          type={selectType(0)}
          // type="boxPlot"
          series={CHART_DATA}
          options={chartOptions}
          height={340}
        />
      </ChartWrapperStyle>

    </Card>
  );
}
