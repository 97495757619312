import Cookies from 'js-cookie';

const axios = require('axios').default;
const setCookie = require('set-cookie-parser');

class NewOdooLib {
  constructor(config) {
    config = config || {};
    this.username = config.username;
    this.password = config.password;
    this.database = config.database;
    this.session_id = null;
    this.context = config.context;
    this.host = config.host || '';
  }

  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    return headers;
  }

  async authenticate(creds = {}) {
    if (creds.username) {
      this.username = creds.username;
    }
    if (creds.password) {
      this.password = creds.password;
    }
    const body = JSON.stringify({
      params: {
        db: this.database,
        login: this.username,
        password: this.password,
      },
    });
    const requestConfig = {
      method: 'POST',
      headers: this.getHeaders(body),
      data: body,
      withCredentials: false,
      baseURL: this.getBaseURL(),
      url: '/web/session/authenticate',
    };
    const resp = await axios(requestConfig);
    const parsedCookies = setCookie.parse(resp, {
      decodeValues: true
    });
    this.session_id = parsedCookies.find((d) => d.name === 'session_id')?.value || Cookies.get('session_id');
    return resp;
  }

  search(model, params) {
    return this._request(
      '/web/dataset/call_kw',
      {
        model,
        method: 'search',
        args: [params.domain],
        kwargs: {
          context: this.context,
        },
      }
    );
  }

  searchRead(model, params) {
    return this._request(
      '/web/dataset/call_kw',
      {
        model,
        method: 'search_read',
        args: [],
        kwargs: {
          context: this.context,
          domain: params.domain,
          offset: params.offset,
          limit: params.limit,
          order: params.order,
          fields: params.fields,
        },
      }
    );
  }

  read(model, params) {
    return this._request(
      '/web/dataset/call_kw',
      {
        model,
        method: 'read',
        args: [params.ids],
        kwargs: {
          fields: params.fields,
        },
      }
    );
  }

  create(model, params) {
    return this._request(
      '/web/dataset/call_kw',
      {
        model,
        method: 'create',
        args: [params],
        kwargs: {
          context: this.context,
        },
      }
    );
  }

  update(model, id, params) {
    if (id) {
      return this._request(
        '/web/dataset/call_kw',
        {
          kwargs: {
            context: this.context,
          },
          model,
          method: 'write',
          args: [[id], params],
        }
      );
    }
  }

  delete(model, id) {
    return this._request(
      '/web/dataset/call_kw',
      {
        model,
        method: 'unlink',
        args: [[id]],
        kwargs: {
          context: this.context,
        },
      }
    );
  }

  getBaseURL() {
    return `${this.host}${this.port ? `:${this.port}` : ''}`;
  }

  async _request(path, params) {
    params = params || {};
    const baseURL = this.getBaseURL();
    if (!this.session_id) {
      await this.authenticate();
    }
    const headers = this.getHeaders();
    headers.cookie = `session_id=${this.session_id};`;
    const requestConfig = {
      method: 'POST',
      headers,
      data: JSON.stringify({
        jsonrpc: '2.0',
        id: new Date().getUTCMilliseconds(),
        method: 'call',
        params,
      }),
      withCredentials: false,
      url: `${baseURL}${path || '/'}`,
    };
    return axios(requestConfig);
  }
}
const odoo = new NewOdooLib({
  // host: 'abc.com',
  // port: null,
  database: 'demo',
});
export default odoo;
