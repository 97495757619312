import { createAction, handleActions } from 'redux-actions';
// import { hashHistory } from 'react-router';
import Cookies from 'js-cookie';
import odoo from '../Odoo';

const LOGIN_REQUEST = 'LOGIN_REQUEST';

const initialState = {
  isAuthenticated: false,
};
export default handleActions(
  {
    LOGIN_REQUEST: (state, action) => {
      const isAuthenticated = !action?.payload?.data?.error;
      const result = action?.payload?.data?.result;
      return {
        ...state,
        isAuthenticated,
        ...result
      };
    },
    LOGOUT_REQUEST: (state) => {
      Cookies.remove('session_id', '');
      return { ...state, ...initialState };
    }
  },
  initialState
);
export const loginUser = createAction(LOGIN_REQUEST, (creds) => odoo.authenticate(creds)
  .then((res) => {
    if (res && res.status && res.status === 200) {
      if (localStorage.lastpage || !localStorage.lastpage === '/login') {
        // hashHistory.push(localStorage.gs_lastpage);
      } else {
        // hashHistory.push('/dashboard');
      }
      return res;
    }
  }).catch((res) => res));
